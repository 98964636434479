import React, { useEffect, useState } from 'react';

import { buttonToggle } from './filter.module.scss';
import { IFilter } from '../../models/filter.model';
import { IOption } from '../../models/option.model';
import useTranslations from '../../hooks/use-translations';

import RadioGroup from './radio-group';
import OptionTabGroup from "./option-tab-group";

interface IFilterProps {
    className?: string;
    filter: IFilter;
    visibleOnStart?: number;
}

const Filter: React.FC<IFilterProps> = ({ className = '', filter, visibleOnStart = 7 }) => {
    const { options, type, label, paramName } = filter;
    const t = useTranslations('Filter');
    const [showAllOptions, setShowAllOptions] = useState(false);
    const [visibleOptions, setVisibleOptions] = useState(
        getVisibleAndEnabledOptions(showAllOptions, visibleOnStart, filter)
    );

    const enabledOptions = options.filter((option) => option.enabled);
    const hasMoreOptions = enabledOptions.length > visibleOnStart && type !== 'radio-range';

    const handleToggleOptions = () => {
        setShowAllOptions((prev) => !prev);
    };

    useEffect(() => {
        setVisibleOptions(getVisibleAndEnabledOptions(showAllOptions, visibleOnStart, filter));
    }, [filter, showAllOptions, visibleOnStart]);

    return (
        <div className={className}>
            {filter.type === 'checkbox' && (
                <OptionTabGroup label={label} name={paramName} options={visibleOptions} singleSelection={true} />
            )}
            {filter.type === 'radio' && (
                <RadioGroup
                    label={label}
                    name={paramName}
                    options={visibleOptions}
                    context="filters"
                    canUncheck={true}
                />
            )}
            {filter.type === 'radio-range' && (
                <RadioGroup
                    label={label}
                    name={paramName}
                    options={visibleOptions}
                    context="filters"
                    canUncheck={true}
                    hasRangeInputs={true}
                    rangeMax={filter.range?.max}
                    rangeMin={filter.range?.min}
                />
            )}
            {hasMoreOptions && (
                <button onClick={handleToggleOptions} className={buttonToggle}>
                    {visibleOptions.length > visibleOnStart ? t.button.less : t.button.more}
                </button>
            )}
        </div>
    );
};

function getVisibleAndEnabledOptions(
    showAll: boolean,
    visibleOnStart: number,
    filter: IFilter
): IOption[] {
    const enabledOptions = filter.options.filter((option) => option.enabled);
    if (showAll || filter.type === 'radio-range') return enabledOptions;
    return enabledOptions.slice(0, visibleOnStart);
}

export default Filter;

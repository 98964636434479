import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { list } from './filters.module.scss';
import { noScroll } from '../../layouts/widget-layout.module.scss';
import { IFilters } from '../../models/filter.model';
import Filter from '../molecules/filter';

interface IFiltersProps {
    className?: string;
    filters: IFilters;
    visibleOnStart?: number;
    isOpen?: boolean;
}

const Filters: React.FC<IFiltersProps> = ({
    className = '',
    filters,
    visibleOnStart,
    isOpen = false,
}) => {
    const visibleFilters = getVisibleFilters(filters);
    const [isLayerOpen, setLayerIsOpen] = useState(false);

    useEffect(() => {
        setLayerIsOpen(isOpen);
    }, [isOpen]);

    return (
        <div className={className}>
            {isLayerOpen && <Helmet bodyAttributes={{ class: noScroll }} />}
            <div>
                <div>
                    <ul className={list}>
                        {visibleFilters.map((filter) => {
                            return (
                                <li key={`filter-item-${filter.paramName}`}>
                                    <Filter filter={filter} visibleOnStart={visibleOnStart} />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

function getVisibleFilters(filters: IFilters) {
    return Object.values(filters).filter((filter) => {
        return filter.visible;
    });
}

export default Filters;

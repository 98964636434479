import React from 'react';
import { Link } from 'gatsby';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    wrapper,
    heading,
    headingText,
    ratio,
    image,
    category,
    button,
} from './product-card.module.scss';
import { IProduct } from '../../models/product.model';
import useTranslations from '../../hooks/use-translations';
import Button from "../atoms/button";

interface IProductCardProps {
    className?: string;
    product: IProduct;
    NameTag?: React.ElementType;
}

const ProductCard: React.FC<IProductCardProps> = ({ className = '', product, NameTag = 'h2' }) => {
    const t = useTranslations('ProductCard');

    return (
        <div
            className={`${wrapper} ${className}`}
        >
            {product.categories && product.categories.length > 0 && <p className={category}>{product.categories[0].name}</p>}
            <Link className={heading} to={product.pathname}>
                <NameTag className={headingText}>{product.name}</NameTag>
            </Link>
            <Link className={image} to={product.pathname} title={`${t.goTo} ${product.name}`}>
                <Image media={product.media} ratioClass={ratio} />
            </Link>
            <Button as={'link'} to={product.pathname} className={button} context={'productCard'}>
                {t.details}
            </Button>
        </div>
    );
};

export default ProductCard;
